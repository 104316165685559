import React, {
    Component
}
from 'react';
import {
    HashRouter,
    Route,
    Switch
}
from 'react-router-dom';
// import { renderRoutes } from 'react-router-config';
import './App.scss';
import {
    Provider
}
from "react-redux";
import store from "./store";



// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));


// Pages
const Login = React.lazy(() => import('./views/Pages/Login'));
const Register = React.lazy(() => import('./views/Pages/Register'));
const Page404 = React.lazy(() => import('./views/Pages/Page404'));
const Page500 = React.lazy(() => import('./views/Pages/Page500'));
const Reset = React.lazy(() => import('./views/Pages/Reset'));
const Success = React.lazy(() => import('./views/Pages/Success'));
const SetPassword = React.lazy(()=> import('./views/Pages/SetPassword'));


class App extends Component {

    render() {

        const loading = () =>  < div className = "animated fadeIn pt-3 text-center" > Loading... <  / div >

        return (
          < Provider store = {store}>
          < HashRouter >
            < React.Suspense fallback = {loading()} >
            < Switch >
              <Route exact path = "/register" name = "Register Page" component = {Register} />
              <Route exact path = "/reset" name = "Reset Password Page" component = {Reset} />
              <Route exact path = "/set-password/:inviteToken" name = "Set Password Page" component = {SetPassword} />
              <Route exact path="/login" name=" Login Page " component={Login} />
              <Route exact path="/400" name="Page 404" render={props => <Page404 {...props}/>} />
              <Route exact path="/unauthorized" name="Unauthorized" render={props => <Page500 {...props}/>} />
              <Route exact path="/success" name="Success" render={props => <Success {...props}/>} />
              <Route path="/" name="Home" render={props => <DefaultLayout {...props}/>} />
            </Switch>
            </React.Suspense>
          </HashRouter>
          </Provider>
        );
	  }
	}


export default App;
